<template>
  <!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <!-- <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    v-if="selectList"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    > -->
  <form class="form">
    <div class="box" :name="ind" v-for="(realat,ind) in resLogin.form['relative']" :key="ind">
      <div class="input-box">
        <span class="span-tit">*是否紧急联系人</span
        ><el-select
          v-model="resLogin.form['relative'][ind].DC_YES_OR_NO"
          placeholder="请选择"
          clearable
        >
          <el-option
            :value="option.fieldvalue"
            v-for="(option, index) in isList"
            :key="index"
            :label="option.xlatshortname"
          >
          </el-option></el-select
        ><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">*姓氏</span
        ><el-input
          type="text"
          v-model="resLogin.form['relative'][ind].LAST_NAME"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">*名字</span
        ><el-input
          type="text"
          v-model="resLogin.form['relative'][ind].FIRST_NAME"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">*与员工关系</span
        ><el-select
          value=""
          v-model="resLogin.form['relative'][ind].RELATIONSHIP"
          placeholder="请选择"
          clearable
        >
          <el-option
            :value="option.fieldvalue"
            v-for="(option, index) in selectList.relationship_list"
            :key="index"
            :label="option.xlatshortname"
          >
          </el-option></el-select
        ><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">性别</span>
        <el-select
          v-model="resLogin.form['relative'][ind].SEX"
          value=""
          clearable
          placeholder="请选择"
        >
          <el-option
            :value="option.fieldvalue"
            v-for="(option, index) in selectList.sex_list"
            :key="index"
            :label="option.xlatshortname"
          >
          </el-option> </el-select
        ><span class="descr"></span>
      </div>

      <div class="input-box">
        <span class="span-tit">出生日期</span
        ><el-input
          type="date"
          v-model="resLogin.form['relative'][ind].BIRTHDATE"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">*详细地址</span
        ><el-input
          type="text"
          name=""
          maxlength="55"
          v-model="resLogin.form['relative'][ind].ADDRESS1"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">*电话</span
        ><el-input
          type="text"
          name=""
          maxlength="24"
          v-model="resLogin.form['relative'][ind].PHONE"
        /><span class="descr"></span>
      </div>

      <div class="input-box">
        <span class="span-tit">职业</span
        ><el-input
          type="text"
          name=""
          maxlength="40"
          v-model="resLogin.form['relative'][ind].OCCUPATION"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">职务</span
        ><el-input
          type="text"
          name=""
          maxlength="64"
          v-model="resLogin.form['relative'][ind].DC_PRES_DUTY"
        /><span class="descr"></span>
      </div>
      <div class="input-box">
        <span class="span-tit">工作单位</span
        ><el-input
          type="text"
          name=""
          maxlength="64"
          v-model="resLogin.form['relative'][ind].DC_PRES_WORK_UNIT"
        /><span class="descr"></span>
      </div>
    </div>
  </form>
  <!-- </a-tab-pane>
  </a-tabs> -->
</template>
<script>
import { useStore } from "vuex";
import { ref, reactive } from "vue";
import { computed } from "vue";
export default {
  name: "family",
  props: {},
  setup() {

    const isList = ref([
      {
        xlatshortname: "是",
        fieldvalue: "Y",
      },
      {
        xlatshortname: "否",
        fieldvalue: "N",
      },
    ]);
    const panes = ref([]);
    const store = useStore();
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    const relative = 
        {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        DEPENDENT_BENEF: "",
        LAST_NAME: "",
        FIRST_NAME: "",
        RELATIONSHIP: "",
        DC_YES_OR_NO: "",
        SEX: "",
        BIRTHDATE: "",
        OCCUPATION: "",
        DC_PRES_WORK_UNIT: "",
        DC_PRES_DUTY: "",
        PHONE: "",
        ADDRESS1: "",
      }
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
if(resLogin.value.form['relative'].length < 3){
  console.log('asdsadkjdhfsahkjgkfdsafhiudshf');
  
  for(let i = resLogin.value.form['relative'].length;i<3;i++){
    resLogin.value.form['relative'].push({...relative})
  }
  store.dispatch("userInfo/setUserData", resLogin);

}
    // layui标签页
    let index = 1; //亲属信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
   /* if (resLogin.value.form["relative"].length == 0) {
      panes.value.push({
        title: `亲属信息`,
        content: "",
        key: `${index}`,
        closable: false,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["relative"].length; i++) {
        if (i == 0) {
          panes.value.push({
            title: `亲属信息`,
            content: "",
            key: `${index}`,
            closable: false,
          });
        } else {
          panes.value.push({
            title: `亲属信息`,
            content: "",
            key: `${index}`,
          });
        }
        index = index + 1;
      }
    }
    */
    //=================

    // const activeKey = ref(panes.value[0].key); --这个不要忘了
    const newTabIndex = ref(0);
    //添加页签
    /*const add = () => {
      let relative = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        DEPENDENT_BENEF: "",
        LAST_NAME: "",
        FIRST_NAME: "",
        RELATIONSHIP: "",
        DC_YES_OR_NO: "",
        SEX: "",
        BIRTHDATE: "",
        OCCUPATION: "",
        DC_PRES_WORK_UNIT: "",
        DC_PRES_DUTY: "",
        PHONE: "",
        ADDRESS1: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `亲属信息`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["relative"].push(relative);
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      console.log("adddddddddddddd:", resLogin.value.form["relative"]);
      index = index + 1;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    */
    //删除页签
  /*  const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["relative"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
*/
    store.commit("setChildData", resLogin.value.form);
 /*   const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };
*/
    return {
      resLogin,
      index,

     /* add,
      remove,
      onEdit,*/
      panes,
      selectList,
      isList,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */

.box {
  border-bottom: 1px solid rgb(226, 29, 29);
  border-radius: 20px;
  box-shadow: 2px 3px 2px rgb(224, 224, 224);
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }
  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;
    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }
    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;
      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .box {
    gap: 10px;
    .input-box {
      gap: 10px;
      width: 100%;
      .span-tit {
        width: 40%;
      }
      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>

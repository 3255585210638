// import { inject } from "vue";
import axios from 'axios';
export const request = {
  methods: {
    //=======================================get======================================
    async requestGet(url, stateCode) {
    //   let REQUEST = inject("axios");
    // url = "/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/"
      try {
        const response = await axios.get(url);

        if (response.data.stateCode === stateCode || response.data.returnCode === stateCode) {
          // resContent.value = response.data.content;
          return { response: response };
        }else{
            //弹窗错误信息
        }
      } catch (error) {
        console.error(error);
      }
    },
    //=======================================post======================================
    async requestPost(url,body,stateCode) {
       url = "/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/"
      //  SVA_EXTRANET/111111 生产环境不需要认证
      // const username = "SVA_EXTRANET";
      // const password = "111111"
      //  const authHeader = 'Basic ' + btoa(username + ':' + password);
      try {
        const response = await axios.post(url,body,{
          headers:{
            // 'Authorization': authHeader
          }
        });
        
        if (response.data.stateCode === stateCode || response.data.returnCode === stateCode) {
          // resContent.value = response.data.content;
          return { response: response};
        }else{
            //弹窗错误信息
        }
      } catch (error) {
        console.error(error);
      }
    },

    
  },
};

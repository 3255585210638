<template>
  <div class="content-box">
    <div class="content">
      <!-- 路由出口 -->
      <!-- <em class="blink-text" style="width: 100%;text-align: center;display: block;margin-top: 30px;">请选择要填写的内容！</em> -->
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
    </div>
    <div class="buttons-box">
      <button class="btn-middle button" @click="save">保存</button>
      <button class="btn-middle button" @click="submitForm">提交</button>
    </div>
    <em
      style="
        display: block;
        width: 100%;
        text-align: center;
        color: var(--color-red-one);
      "
      >*关闭浏览器将会失去未保存的信息，请及时保存</em
    >
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { watch, computed } from "vue";
import { useStore } from "vuex";
import { request } from "../mixin/axios";
import { ElMessageBox } from "element-plus";

const router = useRouter();
const store = useStore();
const parentData = computed(() => store.state.childData);
// const fileImgList = computed(() => store.state.fileImgList);
const isSame_Nid = computed(() => {
  return store.state.isSame_Nid;
});
const isSame_Law = computed(() => {
  return store.state.isSame_Law;
});
const otherSchool = computed(() => {
  return store.state.otherSchool;
});
watch(
  () => router.currentRoute.value,
  () => {}
);
const addressInfo_save = computed(() => {
  return parentData.value["address-info"].some((item) => !item.ADDRESS_TYPE);
});
// const jobQualification_save = computed(() => {
//   return parentData.value["professional-qual"].some((item) => !item.DC_PRQUID);
// });
const beforeWorkInfo_save = computed(() => {
  return parentData.value["work-exp"].some((item) => {
    if (item.EMPLOYER) {
      return !item.START_DT;
    } else {
      return false;
    }
  });
});
const educationInfo_save = computed(() => {
  return parentData.value["edu-exp"].some((item) => !item.BEGIN_DT);
});
const familyInfo_save = computed(() => {
  return parentData.value["relative"].some(
    (item) => !item.LAST_NAME || !item.FIRST_NAME
  );
});
const language_save = computed(() => {
  return parentData.value["language"].some((item) => !item.LANG_CD);
});
const userBankInfo_save = computed(() => {
  return parentData.value["bank-info"].some(
    (item) => !item.ACCOUNT_EC_ID || !item.BRANCH_EC_CD
  );
});
const basicInfo = computed(() => {
  if (
    parentData.value["base-info"].DC_APP_ROOM &&
    parentData.value["base-info"].DC_APP_ROOM == "Y"
  ) {
    if (
      !(
        parentData.value["base-info"].DC_ROOM_DATE &&
        parentData.value["base-info"].DC_ROOM_ADRESS2
      )
    ) {
      return true;
    }
  }
  if (
    parentData.value["base-info"].DC_INTL_STD &&
    parentData.value["base-info"].DC_INTL_STD == "Y"
  ) {
    if (!parentData.value["base-info"].DC_INTL_STD_DATE) {
      return true;
    }
  }
  return (
    !parentData.value["base-info"].LAST_NAME ||
    !parentData.value["base-info"].FIRST_NAME ||
    // !parentData.value["base-info"].PREF_FIRST_NAME ||
    !parentData.value["base-info"].NATIONAL_ID_TYPE ||
    !parentData.value["base-info"].NATIONAL_ID ||
    !parentData.value["base-info"].YESNO_DROPDOWN ||
    !parentData.value["base-info"].SEX ||
    !parentData.value["base-info"].EMAIL_ADDR ||
    !parentData.value["base-info"].PHONE ||
    !parentData.value["base-info"].MAR_STATUS ||
    (parentData.value["base-info"].MAR_STATUS == "B"
      ? !parentData.value["base-info"].MAR_STATUS_DT
      : false) ||
    !parentData.value["base-info"].FT_STUDENT ||
    !parentData.value["base-info"].DC_INTL_STD ||
    !parentData.value["base-info"].DC_APP_ROOM ||
    !parentData.value["base-info"].HUKOU_TYPE_CHN ||
    !parentData.value["base-info"].CONTRIB_AREA_CHN ||
    !parentData.value["base-info"].FLAG1 ||
    !parentData.value["base-info"].FLAG ||
    !parentData.value["base-info"].START_DT ||
    (parentData.value["base-info"].YESNO_DROPDOWN == "N"
      ? !parentData.value["base-info"].END_DT
      : false) ||
    !parentData.value["base-info"].START_DT_CHN ||
    !parentData.value["base-info"].BIRTHCOUNTRY ||
    !parentData.value["base-info"].HIGHEST_EDUC_LVL
  ); //|| !item.field2|| !item.field2
});
const familyInfo = computed(() => {
  let rela;
  for (let i = 0; i < parentData.value["relative"].length; i++) {
    if (
      parentData.value["relative"][i].LAST_NAME ||
      parentData.value["relative"][i].FIRST_NAME
    ) {
      rela =
        !parentData.value["relative"][i].DC_YES_OR_NO || //是否紧急联系人
        !parentData.value["relative"][i].LAST_NAME || //
        !parentData.value["relative"][i].FIRST_NAME || //
        !parentData.value["relative"][i].RELATIONSHIP || //和员工关系
        !parentData.value["relative"][i].ADDRESS1 || //详细地址
        !parentData.value["relative"][i].PHONE; //
      rela = rela ? "0" : "1";
      if (rela == "0") {
        return;
      } else if (rela == "1" && i == parentData.value["relative"].length - 1) {
        return;
      }
    } else {
      rela =
        parentData.value["relative"][i].DC_YES_OR_NO ||
        parentData.value["relative"][i].LAST_NAME ||
        parentData.value["relative"][i].FIRST_NAME ||
        parentData.value["relative"][i].RELATIONSHIP ||
        parentData.value["relative"][i].ADDRESS1 ||
        parentData.value["relative"][i].PHONE;
      rela = rela ? "2" : "1";
      if (rela == "2") {
        return;
      } else if (rela == "1" && i == parentData.value["relative"].length - 1) {
        return;
      }
    }
  }
  return rela;
});
const rpInfo = computed(() => {
  let isrp;
  for (let i = 0; i < parentData.value["reward-punishment"].length; i++) {
    if (parentData.value["reward-punishment"][i].DC_RANDPNAME) {
      isrp =
        !parentData.value["reward-punishment"][i].DC_RANDPNAME || // 奖惩名称
        !parentData.value["reward-punishment"][i].DC_RANDPCATE || // 奖惩类别
        !parentData.value["reward-punishment"][i].DC_RANDPLV || // 奖惩级别
        !parentData.value["reward-punishment"][i].CURRENCY_CD || // 币种
        !parentData.value["reward-punishment"][i].DC_COMMENTS || // 奖惩事由
        !parentData.value["reward-punishment"][i].DC_RANDPTARGET; // 奖惩对象
      isrp = isrp ? "0" : "1";
      if (isrp == "0") {
        return;
      } else if (
        isrp == "1" &&
        i == parentData.value["reward-punishment"].length - 1
      ) {
        return;
      }
    } else {
      isrp =
        parentData.value["reward-punishment"][i].DC_RANDPNAME ||
        parentData.value["reward-punishment"][i].DC_RANDPCATE ||
        parentData.value["reward-punishment"][i].DC_RANDPLV ||
        parentData.value["reward-punishment"][i].CURRENCY_CD ||
        parentData.value["reward-punishment"][i].DC_COMMENTS ||
        parentData.value["reward-punishment"][i].DC_RANDPTARGET;
      isrp = isrp ? "2" : "1";
      if (isrp == "2") {
        return;
      } else if (
        isrp == "1" &&
        i == parentData.value["reward-punishment"].length - 1
      ) {
        return;
      }
    }
  }
  return isrp;
});

const addressInfo = computed(() => {
  let addr_0;
  let addr_1;
  let addr_2;

  addr_0 =
    !parentData.value["address-info"][0].ADDRESS_TYPE ||
    !parentData.value["address-info"][0].COUNTRY ||
    !parentData.value["address-info"][0].ADDRESS1 ||
    !parentData.value["address-info"][0].CITY ||
    !parentData.value["address-info"][0].STATE ||
    !parentData.value["address-info"][0].COUNTY;
  if (isSame_Law.value == "N") {
    addr_1 =
      !parentData.value["address-info"][1].ADDRESS_TYPE ||
      !parentData.value["address-info"][1].COUNTRY ||
      !parentData.value["address-info"][1].ADDRESS1 ||
      !parentData.value["address-info"][1].CITY ||
      !parentData.value["address-info"][1].STATE ||
      !parentData.value["address-info"][0].COUNTY;
  } else {
    addr_1 = addr_0;
  }
  if (isSame_Nid.value == "N") {
    addr_2 =
      !parentData.value["address-info"][2].ADDRESS_TYPE ||
      !parentData.value["address-info"][2].COUNTRY ||
      !parentData.value["address-info"][2].ADDRESS1 ||
      !parentData.value["address-info"][2].CITY ||
      !parentData.value["address-info"][2].STATE ||
      !parentData.value["address-info"][0].COUNTY;
  } else {
    addr_2 = addr_0;
  }
  if (addr_0 || addr_1 || addr_2) {
    console.log("ttttttttttttttttttttttttttt");

    return true;
  } else {
    console.log("who:", addr_0, addr_1, addr_2);

    console.log("fffffffffffffffffffffffffffffffff");

    return false;
  }
});
const beforeWorkInfo = computed(() => {
  return parentData.value["work-exp"].some((item) => {
    if (item.EMPLOYER) {
      return (
        !item.START_DT ||
        !item.END_DT ||
        !item.EMPLOYER ||
        !item.DC_WORK_CONTENT ||
        !item.ENDING_TITLE
      );
    } else {
      return false;
    }
  });
});

const educationInfo = computed(() => {
  let isBenKe;
  let isEdu;
  for (let i = 0; i < parentData.value["edu-exp"].length; i++) {

    if (
      parentData.value["edu-exp"][i].DC_EDU_EDUCATION == "11" ||
      parentData.value["edu-exp"][i].DC_EDU_EDUCATION == "12"
    ) {
      if (parentData.value["edu-exp"][i].DC_IS_HIGH_EDU == "Y") {
        let isBen = false;
        //当前学历是本科以上，并且是最高学历
        for (let j = 0; j < parentData.value["edu-exp"].length; j++) {
          isBenKe =  parentData.value["edu-exp"][j].DC_EDU_EDUCATION == "21" ? "1" : "3"; //3:不是本科
          if(isBenKe == "1"){
          isBen = true;
        }
        }


        if (!isBen && isBenKe == "3") {
          isEdu = '3'
          break; //没有本科数据
        }
      }
    }
    //正常的字段必填校验
    if (parentData.value["edu-exp"][i].SCHOOL) {
      console.log('教育正常校验进入了');
      
      isEdu = parentData.value["edu-exp"].some(
        (item) =>
          !item.BEGIN_DT || //入学时间
          !item.END_DT || //毕业时间
          !item.SCHOOL || //学校
          !item.DC_LEARN_FORMS || //学习形式
          !item.MAJOR || //专业
          !item.DC_MAJOR_TYPE || //专业类型
          !item.DC_EDU_EDUCATION || //学历
          !item.DC_DEU_DEGREE||//学位
          !item.DC_IS_HIGH_EDU || //是否最高学历
          !item.DC_GRADUATE_NUMBER || //学历证书号
          !item.DC_IS_HIGH_DEGREE || //是否最高学位
          !item.DC_DEGREE_NUMBER || //学位证书号
          !item.DC_IS_FIRST_EDU || //是否第一学历
          (["11", "12", "21", "22", "23","61","62","63","70","71"].includes(item.DC_EDU_EDUCATION)
            ? !item.DC_GRADUATE_DATE
            : false) || //学历获取时间
            (["10", "20", "23", "30"].includes(item.DC_DEU_DEGREE)
            ? !item.DC_DEGREE_DATE
            : false) || //学位获取时间
          (!["11", "12", "21", "22", "23"].includes(item.DC_EDU_EDUCATION)
            ? !otherSchool.value
            : false) ||//其他院校
            (item.DC_EDU_EDUCATION=='21' ? !item.DC_MAJOR_BATCH:false)
      );
      isEdu = isEdu ? "0" : "1";
      if (isEdu == "0") {
        break ;
      }
    } else {
      console.log('教育else校验');
      
      isEdu = parentData.value["edu-exp"].some(
        (item) =>
          item.BEGIN_DT || //入学时间
          item.END_DT || //毕业时间
          item.SCHOOL || //学校
          item.DC_LEARN_FORMS || //学习形式
          item.MAJOR || //专业
          item.DC_MAJOR_TYPE || //专业类型
          item.DC_EDU_EDUCATION || //学历
          item.DC_IS_HIGH_EDU || //是否最高学历
          item.DC_GRADUATE_NUMBER || //学历证书号
          item.DC_GRADUATE_DATE || //学历获取时间
          item.DC_IS_HIGH_DEGREE || //是否最高学位
          otherSchool.value || //其他院校
          item.DC_SCHLLO_TYPE ||
          item.DC_MAJOR_BATCH ||
          item.DC_IS_FIRST_EDU ||
          item.DC_DEU_DEGREE ||
          item.DC_DEGREE_NUMBER ||
          item.DC_DEGREE_DATE
      );
      isEdu = isEdu ? "2" : "1";
      if (isEdu == "2") {
        break;
      }
    }
  }
  console.log('edu:',isEdu);
  
  return isEdu;
});
const jobTitileInfo = computed(() => {
  let jobTit = "";
  if (parentData.value["base-info"].FLAG1 == "Y") {
    jobTit = parentData.value["job-title"].some(
      (item) =>
        !item.DC_TITLE_NAME ||
        !item.DC_TITLE_TYPE ||
        !item.DC_TITLE_LEVEL ||
        !item.DC_CERT_NUM ||
        !item.DC_ISS_DATE ||
        !item.DC_ISS_UNIT
    ); //|| !item.field2|| !item.field2
    jobTit = jobTit ? "0" : "1";
    if (jobTit == "0") {
      return '0';
    }
  } else {
    jobTit = parentData.value["job-title"].some(
      (item) =>
        item.DC_TITLE_NAME ||
        item.DC_TITLE_TYPE ||
        item.DC_TITLE_LEVEL ||
        item.DC_CERT_NUM ||
        item.DC_ISS_DATE ||
        item.DC_ISS_UNIT ||
        item.DC_REVW_MTD ||
        item.COMMENTS_256
    );
    jobTit = jobTit ? "2" : "1";
    if (jobTit == "2") {
      return '2';
    }
  }
  return jobTit;
});

const jobQualification = computed(() => {
  let jqua = false;
  for (let i = 0; i < parentData.value["professional-qual"].length; i++) {
    if (parentData.value["professional-qual"][i].DC_PRQUID) {
      jqua = parentData.value["professional-qual"].some(
        (item) => !item.DC_QUALIFILV || !item.DC_ISTOPLV || !item.DATE || !item.DATE1 || !item.DC_ISTOPLV || !item.COMPANY_NAME


      ); //|| !item.field2|| !item.field2
      jqua = jqua ? "0" : "1";
      if (jqua == "0") {
        return;
      }
    } else {
      jqua = parentData.value["professional-qual"].some(
        (item) =>
          item.DC_QUALIFILV ||
          item.DC_ISTOPLV ||
          item.DATE ||
          item.DC_GRADING ||
          item.DC_THRTCGRAD ||
          item.DC_OPERGRAD ||
          item.DC_CERTIFINO ||
          item.COMPANY_NAME ||
          item.DATE1 ||
          item.COMMENTS_256
      );
      jqua = jqua ? "2" : "1";
      if (jqua == "2") {
        return;
      }
    }
  }
  return jqua;
});

const language = computed(() => {
  //0:有未填  1：通过  2：选否，有信息
  let lan = "";
  if (parentData.value["base-info"].FLAG == "Y") {
    lan = parentData.value["language"].some(
      (item) =>
        !item.LANG_CD ||
        // !item.DC_LANGUAGE_LEVEL ||
        !item.DC_AUTH_NAME ||
        !item.DC_PROVE_ID
      // !item.DC_AUTH_NUMBER ||
      // !item.DC_ISS_UNIT
    ); //|| !item.field2|| !item.field2
    lan = lan ? "0" : "1";
  } else {
    lan = parentData.value["language"].some(
      (item) =>
        item.LANG_CD ||
        item.DC_LANGUAGE_LEVEL ||
        item.DC_AUTH_NAME ||
        item.DC_PROVE_ID ||
        item.DC_AUTH_NUMBER ||
        item.DC_ISS_UNIT ||
        item.END_DT ||
        item.COMMENTS_256
    );
    lan = lan ? "2" : "1";
  }
  return lan;
});
const userBankInfo = computed(() => {
  return parentData.value["bank-info"].some(
    (item) => !item.COUNTRY_CD || !item.BANK_CD || !item.ACCOUNT_NAME
  ); //|| !item.field2|| !item.field2
});
// 数据请求接口
const urlLogin = "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
const { requestPost } = request.methods;
const valid = async () => {
  let a = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "23";
  });

  let b = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "22";
  });

  let c = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "21";
  });
  let aa = parentData.value["edu-exp"].filter((i) => {
    return i.DC_IS_HIGH_EDU == "Y";
  });
  let aaa = parentData.value["edu-exp"].filter((i) => {
    return i.DC_IS_FIRST_EDU == "Y";
  });
  if (aa.length > 1 || aa.length < 1) {
    ElMessageBox.alert("必须且只能存在一份第一学历", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  let bb = parentData.value["edu-exp"].filter((i) => {
    return i.DC_IS_HIGH_DEGREE == "Y";
  });
  if (bb.length > 1 || bb.length < 1) {
    ElMessageBox.alert("必须且只能存在一份最高学位", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (a.length > 0) {
    a.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("参加工作时间不能早于大专毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  if (b.length > 0) {
    b.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("首次参加工作时间不能早于专升本毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  if (c.length > 0) {
    c.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("参加工作时间不能早于本科毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  let ee = parentData.value["professional-qual"].filter((i) => {
    return i.DC_ISTOPLV == "Y";
  });
  if (ee.length > 1) {
    ElMessageBox.alert("职业资格只能存在一个最高等级", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (
    parentData.value["base-info"].HUKOU_TYPE_CHN == "LFARMER" ||
    parentData.value["base-info"].HUKOU_TYPE_CHN == "LPRESIDENT"
  ) {
    if (
      parentData.value["base-info"].CONTRIB_AREA_CHN !==
      parentData.value["base-info"].LOCATION
    ) {
      ElMessageBox.alert("请确认户口类别是否正确", "提交失败", {
        confirmButtonText: "确认",
      });
    }
  } else {
    if (
      parentData.value["base-info"].CONTRIB_AREA_CHN ==
      parentData.value["base-info"].LOCATION
    ) {
      ElMessageBox.alert("请确认户口类别是否正确", "提交失败", {
        confirmButtonText: "确认",
      });
      return false;
    }
  }
  let d = parentData.value["relative"].filter((i) => {
    return i.DC_YES_OR_NO == "Y";
  });
  if (d.length > 1 || d.length < 1) {
    ElMessageBox.alert("必须且只能填一个紧急联系人", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }

  if (d.length == 1 && d[0].DC_YES_OR_NO == "Y" && !d[0].PHONE) {
    ElMessageBox.alert("紧急联系人手机号必填", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  /*let bi = parentData.value["bank-info"].filter((i) => {
    return (
      i.ACCOUNT_NAME !==
      parentData.value["base-info"].LAST_NAME +
        parentData.value["base-info"].FIRST_NAME
    );
  });
  if (bi.length > 0) {
    alert(`开户人与本人名字必须一致`);
    return;
  }
*/

  if (basicInfo.value) {
    ElMessageBox.alert("基本信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
      parentData.value["base-info"].NATIONAL_ID
    )
  ) {
    ElMessageBox.alert("基本信息身份证号格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^(?:\+86)?1[3-9]\d{9}$/.test(parentData.value["base-info"].PHONE)
  ) {
    ElMessageBox.alert("基本信息手机号格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      parentData.value["base-info"].EMAIL_ADDR
    )
  ) {
    ElMessageBox.alert("基本信息邮箱格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }else if(parentData.value["base-info"].YESNO_DROPDOWN == "N" && parentData.value["base-info"].END_DT <= parentData.value["base-info"].START_DT){
    ElMessageBox.alert("证件有效期起需要小于证件有效期止", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (language.value == "0") {
    ElMessageBox.alert("语言能力有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (language.value == "2") {
    ElMessageBox.alert(
      "基本信息“是否有语言能力”为否时，请删除语言能力页面数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  }
  if (familyInfo.value == "0") {
    ElMessageBox.alert("亲属信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (familyInfo.value == "2") {
    ElMessageBox.alert(
      "亲属信息中有未填写亲属姓和名的页签，请删除该页签所有数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  } else {
    for (let i = 0; i < parentData.value["relative"].length; i++) {
      if (
        !/^(?:\+86)?1[3-9]\d{9}$/.test(parentData.value["relative"][i].PHONE) &&
        parentData.value["relative"][i].FIRST_NAME &&
        parentData.value["relative"][i].LAST_NAME
      ) {
        ElMessageBox.alert("亲属信息电话格式不对", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      } else {
        if (
          parentData.value["relative"][i].LAST_NAME ==
            parentData.value["base-info"].LAST_NAME &&
          parentData.value["relative"][i].FIRST_NAME ==
            parentData.value["base-info"].FIRST_NAME
        ) {
          ElMessageBox.alert(
            "亲属的姓名不能和本人姓名一致，特殊情况请联系HR",
            "提交失败",
            {
              confirmButtonText: "确认",
            }
          );
          return false;
        }
      }
    }
  }
  if (addressInfo.value) {
    ElMessageBox.alert("地址信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (beforeWorkInfo.value) {
    ElMessageBox.alert(
      "工作经历有未填字段，若没有工作经历请删除此页面所有数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  } else {
    for (let i = 0; i < parentData.value["work-exp"].length; i++) {
      if (
        !/^(?:\+86)?1[3-9]\d{9}$/.test(parentData.value["work-exp"][i].PHONE) &&
        parentData.value["work-exp"][i].PHONE
      ) {
        ElMessageBox.alert("工作经历证明人手机号格式不对", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      } else if (
        parentData.value["work-exp"][i].END_DT &&
        parentData.value["work-exp"][i].START_DT >=
          parentData.value["work-exp"][i].END_DT
      ) {
        ElMessageBox.alert("工作经历开始日期必须小于结束日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    }
  }

  if (educationInfo.value == "0") {
    ElMessageBox.alert("教育经历有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (educationInfo.value == "2") {
    ElMessageBox.alert(
      "教育信息中毕业院校为空时，请删除此页签中数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  } else if (educationInfo.value == "3") {
    ElMessageBox.alert(
      "教育信息中最高学历是本科以上时，请维护本科教育信息",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  } else {
    for (let i = 0; i < parentData.value["edu-exp"].length; i++) {
      if (parentData.value["edu-exp"][i].DC_IS_HIGH_EDU == "Y") {
      if (
        parentData.value["edu-exp"][i].DC_EDU_EDUCATION !=
        parentData.value["base-info"].HIGHEST_EDUC_LVL
      ){
        try {
      await ElMessageBox.confirm(
        "教育信息中最高学历和基本信息中最高教育程度不一致，确定提交么？",
        "警告",
        {
          confirmButtonText: "提交",
          cancelButtonText: "取消",
        }
      );
    } catch (error) {
      return false;
    }
      }
    }


      if (
        parentData.value["edu-exp"][i].BEGIN_DT >=
        parentData.value["edu-exp"][i].END_DT
      ) {
        ElMessageBox.alert("教育经历入学日期必须小于毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    }
  }
  if (jobTitileInfo.value == "0") {
    ElMessageBox.alert("职称信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (jobTitileInfo.value == "2") {
    ElMessageBox.alert(
      "基本信息“是否有职称信息”为否时，请删除职称信息页面数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  }
  let qua = parentData.value["professional-qual"].filter((i) => {
    return i.DC_ISTOPLV == "Y";
  });
  if (jobQualification.value == "0") {
    ElMessageBox.alert("职业资格有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (jobQualification.value == "2") {
    ElMessageBox.alert(
      "职业资格字段未维护时，请清空职业资格页面中其他字段中的内容",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  } else {
    for (let i = 0; i < parentData.value["professional-qual"].length; i++)
      if (
        parentData.value["professional-qual"][i].DC_PRQUID &&
        (qua.length > 1 || qua.length < 1)
      ) {
        ElMessageBox.alert("必须且只能有一个最高等级职业资格", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
  }
  if (userBankInfo.value) {
    ElMessageBox.alert("银行账户信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (rpInfo.value == "0") {
    ElMessageBox.alert("奖惩信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (rpInfo.value == "2") {
    ElMessageBox.alert(
      "若没有奖惩信息，请删除奖惩信息页面所有数据",
      "提交失败",
      {
        confirmButtonText: "确认",
      }
    );
    return false;
  }
  // if (language.value) {
  //   ElMessageBox.alert("语言能力有未填字段", "提交失败", {
  //     confirmButtonText: "确认",
  //   });
  //   return false;
  // }
  return true;
};
const submitForm = async () => {
  console.log('提交了');
  
  if (!store.state.isSubmitButtonClicked) {
   await ElMessageBox.alert("请先点击 -保存附件- 按钮", "警告", {
      confirmButtonText: "返回",
    });
    return ;
  }
  let input_flag = true;
  input_flag = await valid();
  console.log(input_flag);

  let relaIndex = [];
  parentData.value["relative"].forEach((item, i) => {
    // item
    item.DEPENDENT_BENEF = `${i + 1}`;
    if (!(item.FIRST_NAME && item.LAST_NAME)) {
      relaIndex.push(i);
    }
  });
  for (let i = relaIndex.length - 1; i >= 0; i--) {
    let index = relaIndex[i];
    parentData.value["relative"].splice(index, 1);
  }
  parentData.value["edu-exp"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
    if (item.SCHOOL == "99") {
      item.SCHOOL = otherSchool.value[i];
    }
  });
  // if (parentData.value["address-info"].length < 3) {
  //   ElMessageBox.alert(
  //     "地址信息中需添加“工作地居住地址、法定送达地址、身份证地址”的信息",
  //     "警告",
  //     {
  //       confirmButtonText: "确认",
  //     }
  //   );
  //   return;
  // }
  if (isSame_Law.value == "Y") {
    parentData.value["address-info"][1].COUNTRY =
      parentData.value["address-info"][0].COUNTRY;
    parentData.value["address-info"][1].CITY =
      parentData.value["address-info"][0].CITY;
    parentData.value["address-info"][1].STATE =
      parentData.value["address-info"][0].STATE;
    parentData.value["address-info"][1].COUNTY =
      parentData.value["address-info"][0].COUNTY;
    parentData.value["address-info"][1].ADDRESS1 =
      parentData.value["address-info"][0].ADDRESS1;
  }
  if (isSame_Nid.value == "Y") {
    parentData.value["address-info"][2].COUNTRY =
      parentData.value["address-info"][0].COUNTRY;
    parentData.value["address-info"][2].CITY =
      parentData.value["address-info"][0].CITY;
    parentData.value["address-info"][2].STATE =
      parentData.value["address-info"][0].STATE;
    parentData.value["address-info"][2].COUNTY =
      parentData.value["address-info"][0].COUNTY;
    parentData.value["address-info"][2].ADDRESS1 =
      parentData.value["address-info"][0].ADDRESS1;
  }
  parentData.value["bank-info"].forEach((item, i) => {
    item.ACCOUNT_ID = `${i + 1}`;
  });
  parentData.value["work-exp"].forEach((item, i) => {
    item.SEQUENCE_NBR = `${i + 1}`;
  });
  parentData.value["language"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["job-title"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["professional-qual"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["reward-punishment"].forEach((item, i) => {
    item.SEQNO = `${i + 1}`;
  });
  store.dispatch("setLoading", false);

  if (input_flag) {
    console.log('校验文件中');
    
    store.dispatch("setLoading", true);

    // // 假设获取数据的逻辑是异步的
    const requestBody = {
      language: "ZHS",
      appversion: "1.0",
      devicetype: "PC",
      deviceid: "35d1298095ac0ef1",
      devicever: "PC",
      infmod: "RC",
      infid: "SUBMIT",
      biz: {
        // id: "125",
        id: parentData.value["base-info"].DC_CANDDT_ID,
        action: "S",
        form: parentData.value,
      },
    };
    const requestBody_file = {
      language: "ZHS",
      appversion: "1.0",
      devicetype: "PC",
      deviceid: "Extranet registration",
      devicever: "V1",
      infmod: "RC",
      infid: "GETATTACH",
      biz: {
        // id: "125",
        id: parentData.value["base-info"].DC_CANDDT_ID,
      },
    };
    let file_flag = true;
    requestPost(urlLogin, requestBody_file, "0000")
      .then((back) => {
        store.dispatch("setLoading", false);
        let allUploadList = back.response.data.returnData.attachInfo;

        let ph = allUploadList.filter((i) => i.attachType == "11");
        if (ph.length == 0) {
          ElMessageBox.alert("请上传一寸照片附件", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }

        let b = allUploadList.filter((i) => i.attachType == "10");
        if (b.length == 0) {
          ElMessageBox.alert("请上传身份证正面附件", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }
        let c = allUploadList.filter((i) => i.attachType == "23");
        if (c.length == 0) {
          ElMessageBox.alert("请上传身份证反面附件", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }
        let d = allUploadList.filter((i) => i.attachType == "18");
        if (parentData.value["base-info"].FLAG1 == "Y") {
          if (d.length == 0) {
            ElMessageBox.alert("请上传职称信息附件", "提交失败", {
              confirmButtonText: "确认",
            });
            file_flag = false;
            return file_flag;
          }
        } else if (parentData.value["base-info"].FLAG1 == "N") {
          if (d.length != 0) {
            ElMessageBox.alert(
              "是否有职称信息字段为否，请删除职称信息附件",
              "提交失败",
              {
                confirmButtonText: "确认",
              }
            );
            file_flag = false;
            return file_flag;
          }
        }

        let e = allUploadList.filter((i) => i.attachType == "19");
        if (parentData.value["base-info"].FLAG == "Y") {
          if (e.length == 0) {
            ElMessageBox.alert("请上传语言能力附件", "提交失败", {
              confirmButtonText: "确认",
            });
            file_flag = false;
            return file_flag;
          }
        } else if (parentData.value["base-info"].FLAG == "N") {
          if (e.length != 0) {
            ElMessageBox.alert(
              "是否有语言能力字段为否，请删除语言能力附件",
              "提交失败",
              {
                confirmButtonText: "确认",
              }
            );
            file_flag = false;
            return file_flag;
          }
        }
        let f = allUploadList.filter((i) => i.attachType == "12");
        if (f.length !== parentData.value["bank-info"].length) {
          ElMessageBox.alert("请上传银行附件", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }
        let g = allUploadList.filter((i) => i.attachType == "17");
        for (let i = 0; i < parentData.value["professional-qual"].length; i++) {
          if (parentData.value["professional-qual"][i].DC_PRQUID) {
            if (g.length == 0) {
              ElMessageBox.alert("请上传职业资格附件", "提交失败", {
                confirmButtonText: "确认",
              });
              file_flag = false;
              return file_flag;
            }
          } else {
            if (g.length != 0) {
              ElMessageBox.alert(
                "未维护职业资格页面数据，请删除职业资格附件",
                "提交失败",
                {
                  confirmButtonText: "确认",
                }
              );
              file_flag = false;
              return file_flag;
            }
          }
        }

        if (file_flag && input_flag) {
          //  文件验证过后在提交
          store.dispatch("setLoading", true);
          requestPost(urlLogin, requestBody, "0000")
            .then((back) => {
              // selectList.value = back.response.data.returnData
              // store.dispatch('storeValue', selectList.value);
              if (back.response.status == 200) {
                store.dispatch("setLoading", false);
                ElMessageBox.alert("提交成功", "提交完成", {
                  confirmButtonText: "确认",
                }).then(() => {
                  router.push("/error");
                });
              } else {
                ElMessageBox.alert("提交失败，请重试", "提交完成", {
                  confirmButtonText: "确认",
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // requestPost(urlLogin, requestBody1, "0000")
  //   .then((back) => {
  //     // selectList.value = back.response.data.returnData
  //     // store.dispatch('storeValue', selectList.value);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};

const save = async () => {
  let flag = false;
  if (isSame_Law.value == "Y") {
    parentData.value["address-info"][1].COUNTRY =
      parentData.value["address-info"][0].COUNTRY;
    parentData.value["address-info"][1].CITY =
      parentData.value["address-info"][0].CITY;
    parentData.value["address-info"][1].STATE =
      parentData.value["address-info"][0].STATE;
    parentData.value["address-info"][1].COUNTY =
      parentData.value["address-info"][0].COUNTY;
    parentData.value["address-info"][1].ADDRESS1 =
      parentData.value["address-info"][0].ADDRESS1;
  }
  if (isSame_Nid.value == "Y") {
    parentData.value["address-info"][2].COUNTRY =
      parentData.value["address-info"][0].COUNTRY;
    parentData.value["address-info"][2].CITY =
      parentData.value["address-info"][0].CITY;
    parentData.value["address-info"][2].STATE =
      parentData.value["address-info"][0].STATE;
    parentData.value["address-info"][2].COUNTY =
      parentData.value["address-info"][0].COUNTY;
    parentData.value["address-info"][2].ADDRESS1 =
      parentData.value["address-info"][0].ADDRESS1;
  }
  // try {
    // if (addressInfo_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写地址信息中地址类型的页签的相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
    //   // .then(() => {})
    //   // .catch(() => {
    //   //   // return ;
    //   // });
    // }
    // if (beforeWorkInfo_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写工作经历中开始时间的页签的相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
    //   // .then(() => {})
    //   // .catch(() => {
    //   //   // return;
    //   // });
    // }
    // if (educationInfo_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写教育信息中开始时间的页签的相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
      // .then(() => {})
      // .catch(() => {
      //   // return;
      // });
    // }
    // if (familyInfo_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写亲属信息中姓和名字段的页签的亲属相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
    //   // .then(() => {})
    //   // .catch(() => {
    //   //   // return;
    //   // });
    // }
    // if (language_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写语言能力中语言的页签的相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
    //   // .then(() => {})
    //   // .catch(() => {
    //   //   // return;
    //   // });
    // }
    // if (userBankInfo_save.value) {
    //   await ElMessageBox.confirm(
    //     "将不会保存未填写银行账户中银行和银行分行的页签的相关数据，如有遗漏请点击取消",
    //     "警告",
    //     {
    //       confirmButtonText: "保存",
    //       cancelButtonText: "取消",
    //     }
    //   );
    //   // return;
    // }
  // } catch (error) {
  //   console.log(error);
  //   return;
  // }
  let relaIndex = [];
  parentData.value["relative"].forEach((item, i) => {
    item.DEPENDENT_BENEF = `${i + 1}`;
    if (!(item.FIRST_NAME && item.LAST_NAME)) {
      relaIndex.push(i);
    }
  });
  for (let i = relaIndex.length - 1; i >= 0; i--) {
    let index = relaIndex[i];
    parentData.value["relative"].splice(index, 1);
  }

  parentData.value["edu-exp"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
    if (item.SCHOOL == "99") {
      item.SCHOOL = otherSchool.value[i];
    }
  });
  // parentData.value["address-info"].forEach((item,i) => {
  //   item.DEPENDENT_BENEF = `${i+1}`
  // });
  parentData.value["bank-info"].forEach((item, i) => {
    item.ACCOUNT_ID = `${i + 1}`;
  });
  parentData.value["work-exp"].forEach((item, i) => {
    item.SEQUENCE_NBR = `${i + 1}`;
  });
  for (let i = 0; i < parentData.value["language"].length; i++) {
    parentData.value["language"][i].SEQNBR = `${i + 1}`;
  }
  parentData.value["job-title"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["professional-qual"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["reward-punishment"].forEach((item, i) => {
    item.SEQNO = `${i + 1}`;
  });
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "PC",
    infmod: "RC",
    infid: "SUBMIT",
    biz: {
      // id:'125',
      id: parentData.value["base-info"].DC_CANDDT_ID,
      action: "V",
      form: parentData.value,
    },
  };
  if (!flag) {
    store.dispatch("setLoading", true);
    requestPost(urlLogin, requestBody, "0000")
      .then((back) => {
        store.dispatch("setLoading", false);
        ElMessageBox.alert("保存成功", "成功", {
          confirmButtonText: "返回",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>
<style scoped>
.blink-text {
  font-size: var(--pc-text-size-title);
  font-weight: bold;
  color: #000000; /* 文字颜色 */
  animation: blinkAnimation 1s infinite; /* 调用 blinkAnimation 动画 */
}
@keyframes blinkAnimation {
  0% {
    opacity: 1; /* 不透明 */
  }
  50% {
    opacity: 0; /* 半透明 */
  }
  100% {
    opacity: 1; /* 不透明 */
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90vh;
  width: 90%;
  background-color: var(--bg-color);
  padding: 10px;
}

.content {
  background-color: var(--bg-content-color);
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 15px; */
}

.buttons-box {
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-content-color);
  flex-wrap: wrap;

  height: 10%;
  width: 100%;
}
</style>

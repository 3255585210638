<template>
  <!-- <C_header :userN="userName"> </C_header> -->
  <C_header> </C_header>

  <C_body>
    <template #Menu> <C_left></C_left></template>
    <template #content><C_content></C_content></template>
  </C_body>
</template>

<script>
import C_left from "../layout/C-left";
import C_header from "../layout/C-header.vue";
import C_body from "../layout/C-body.vue";
import C_content from "../layout/C-content.vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed, onMounted, ref, nextTick, watch } from "vue";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";
import router from "@/router";

export default {
  name: "all",
  props: {},
  components: {
    C_left,
    C_header,
    C_body,
    C_content,
  },
  setup() {
    const count = ref(0);
    const route = useRoute();
    const store = useStore();
    // 数据请求接口
    const urlLogin =
      "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
    const { requestPost } = request.methods;
    const selectList = ref();
    const imgList = ref();
    const selectData = () => {
      store.dispatch("setLoading", true);
      const requestBody = {
        language: "ZHS",
        appversion: "1.0",
        devicetype: "PC",
        deviceid: "35d1298095ac0ef1",
        devicever: "V1",
        infmod: "XLAT",
        infid: "RCVALUELIST",
        biz: {},
      };
      requestPost(urlLogin, requestBody, "0000")
        .then((back) => {
          selectList.value = back.response.data.returnData;
          store.dispatch("storeValue", selectList.value);
          store.dispatch("setLoading", false);
          count.value = count.value + 1;
        })
        .catch((error) => {
          store.dispatch("setLoading", false);
          ElMessageBox.alert("获取列表信息出错", "提交失败", {
            confirmButtonText: "确认",
          });
        });
    };

    //获取附件信息 --临时注释
    // const getImgData = (resLogin) => {
    //   store.dispatch("setLoading", true);
    //   const requestBody = {
    //     language: "ZHS",
    //     appversion: "1.0",
    //     devicetype: "PC",
    //     deviceid: "35d1298095ac0ef1",
    //     devicever: "V1",
    //     infmod: "RC",
    //     infid: "GETATTACH",
    //     biz: {
    //       // id: "125",
    //       id: resLogin.form["base-info"].DC_CANDDT_ID,
    //     },
    //   };
    //   requestPost(urlLogin, requestBody, "0000")
    //     .then((back) => {
    //       imgList.value = back.response.data.returnData.attachInfo;
    //       store.dispatch("storeImgValue", imgList.value);
    //       store.dispatch("setLoading", false);
    //       count.value = count.value + 1;
    //     })
    //     .catch((error) => {
    //       store.dispatch("setLoading", false);
    //       ElMessageBox.alert("获取附件出错", "错误", {
    //         confirmButtonText: "确认",
    //       });
    //     });
    // };
// const resLo = ref('')
// resLo.value = JSON.parse(route.query.resLogin)
// const userName =ref('')
// userName.value=resLo.value.form['base-info'].DC_NAME_50
    onMounted(() => {
      store.dispatch("userInfo/setUserData", JSON.parse(route.query.resLogin));
      const resLogin = computed(() => {
        return store.state.userInfo.resLogin;
      });
      //亲属信息
      const relative = 
        {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        DEPENDENT_BENEF: "",
        LAST_NAME: "",
        FIRST_NAME: "",
        RELATIONSHIP: "",
        DC_YES_OR_NO: "",
        SEX: "",
        BIRTHDATE: "",
        OCCUPATION: "",
        DC_PRES_WORK_UNIT: "",
        DC_PRES_DUTY: "",
        PHONE: "",
        ADDRESS1: "",
      }
      //教育信息
      const edu_exp = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQNBR: "",
        BEGIN_DT: "",
        END_DT: "",
        SCHOOL: "",
        DC_SCHLLO_TYPE: "",
        DC_LEARN_FORMS: "",
        MAJOR: "",
        DC_MAJOR_TYPE: "",
        DC_EDU_EDUCATION: "",
        DC_MAJOR_BATCH: "",
        DC_CONNECT_FORM: "",
        DC_IS_HIGH_EDU: "",
        DC_GRADUATE_NUMBER: "",
        DC_GRADUATE_DATE: "",
        DC_DEU_DEGREE: "",
        DC_IS_HIGH_DEGREE: "",
        DC_DEGREE_NUMBER: "",
        DC_DEGREE_DATE: "",
      };
      //地址信息
      const address_info = [
        {
          DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
          ADDRESS_TYPE: "NID",
          COUNTRY: "CHN",
          CITY: "",
          STATE: "",
          COUNTY: "",
          ADDRESS1: "",
        },
        {
          DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
          ADDRESS_TYPE: "MAIL",
          COUNTRY: "CHN",
          CITY: "",
          STATE: "",
          COUNTY: "",
          ADDRESS1: "",
        },
        {
          DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
          ADDRESS_TYPE: "HOME",
          COUNTRY: "CHN",
          CITY: "",
          STATE: "",
          COUNTY: "",
          ADDRESS1: "",
        },
      ];
      //银行信息
      const bank_info = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        ACCOUNT_ID: "",
        COUNTRY_CD: "CHN",
        BANK_CD: "",
        BRANCH_EC_CD: "",
        ACCOUNT_EC_ID: "",
        ACCOUNT_NAME: "",
        CURRENCY_CD: "CNY",
        DC_BANK_CODE: "",
        DC_SWIFT_CODE: "",
      };
      //工作经历
      const work_exp = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQUENCE_NBR: "",
        START_DT: "",
        END_DT: "",
        EMPLOYER: "",
        DC_COMPANY_NATURE: "",
        DC_INDUSTRY: "",
        COUNTRY: "CHN",
        ADDRESS100: "",
        DEPT_DESCR: "",
        ENDING_TITLE: "",
        DC_SALARY_RANGE: "",
        CURRENCY_CD: "CNY",
        DC_CERTIFIER: "",
        PHONE: "",
        DC_WORK_CONTENT: "",
        DC_TER_REASON: "",
        DC_IS_INTERSHIP_EX: "",
      };
      //语言能力
      const language = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQNBR: "",
        LANG_CD: "",
        DC_LANGUAGE_LEVEL: "",
        DC_AUTH_NAME: "",
        DC_PROVE_ID: "",
        DC_AUTH_NUMBER: "",
        DC_ISS_UNIT: "",
        END_DT: "",
        COMMENTS_256: "",
      };
      //职称信息
      const job_title = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQNBR: "",
        DC_TITLE_NAME: "",
        DC_TITLE_TYPE: "",
        DC_TITLE_LEVEL: "",
        DC_CERT_NUM: "",
        DC_ISS_DATE: "",
        DC_ISS_UNIT: "",
        DC_REVW_MTD: "",
        COMMENTS_256: "",
      };
      //职业资格
      const professional_qual = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQNBR: "",
        DC_PRQUID: "",
        DC_QUALIFILV: "",
        DC_GRADING: "",
        DC_THRTCGRAD: "",
        DC_OPERGRAD: "",
        DC_ISTOPLV: "",
        DC_CERTIFINO: "",
        DATE: "",
        COMPANY_NAME: "",
        DATE1: "",
        COMMENTS_256: "",
      };
      //奖惩信息
      const reward_punishment = {
        DC_CANDDT_ID: `${resLogin.value.form["base-info"].DC_CANDDT_ID}`,
        SEQNO: "",
        DC_RANDPNAME: "",
        DC_RANDPCATE: "",
        DC_RANDPLV: "",
        DC_RANDPTARGET: "",
        DC_ODN: "",
        DC_PUBDATE: "",
        DC_COMMENTS: "",
        DC_RANDPFORMS: "",
        CURRENCY_CD: "CNY",
        DC_RANDPDATE: "",
        DC_RANDPAMOUNT: "",
      };
      let isDispatch = false;
      selectData();
      // getImgData(resLogin.value);
      // 如果返回的结果中没有值，也就是用户第一次进来的情况，手动把“键”补上

      if (resLogin.value.form["relative"].length <3 ) {
        console.log('alllllllllllllllllllllll');
        
        for(let i=resLogin.value.form["relative"].length;i<3;i++){
          console.log('i',i);
          
        resLogin.value.form["relative"].push({...relative});
        }
        isDispatch = true;
      }
      if (resLogin.value.form["address-info"].length == 0) {
        resLogin.value.form["address-info"].push(...address_info);
        isDispatch = true;
      }
      if (resLogin.value.form["edu-exp"].length == 0) {
        resLogin.value.form["edu-exp"].push(edu_exp);
        isDispatch = true;
      }
      if (resLogin.value.form["bank-info"].length == 0) {
        resLogin.value.form["bank-info"].push(bank_info);
        isDispatch = true;
      }
      if (resLogin.value.form["work-exp"].length == 0) {
        resLogin.value.form["work-exp"].push(work_exp);
        isDispatch = true;
      }
      if (resLogin.value.form["language"].length == 0) {
        resLogin.value.form["language"].push(language);
        isDispatch = true;
      }
      if (resLogin.value.form["job-title"].length == 0) {
        resLogin.value.form["job-title"].push(job_title);
        isDispatch = true;
      }
      if (resLogin.value.form["professional-qual"].length == 0) {
        resLogin.value.form["professional-qual"].push(professional_qual);
        isDispatch = true;
      }
      if (resLogin.value.form["reward-punishment"].length == 0) {
        resLogin.value.form["reward-punishment"].push(reward_punishment);
        isDispatch = true;
      }

      if (isDispatch) {
        store.dispatch("userInfo/setUserData", resLogin);
      }
      count.value = count.value + 1;
      watch(count, (newVal, oldVal) => {
        if (newVal == 2) {
          router.push("/all/basicInfo");
        }
      });
      return {
        resLogin,
        selectData,
        selectList,
        imgList,
        // getImgData,
        // userName 
      };
    });
  },
};
</script>

<style scoped>
.left-box {
}
</style>
